import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from 'axios';
import logo from './logo.svg'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import Drawer from '@mui/material/Drawer';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
//import { ContactSupportOutlined } from "@mui/icons-material";

export default function Landing() {

    const { slug } = useParams();

    const convertDate = (date) => {
        const today = new Date(date);
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const day = today.getDate();
        const formattedMonth = (month < 10) ? '0' + month : month;

        return year + '-' + formattedMonth + '-' + day;
    }

    const convertDate2 = (date) => {
        const today = new Date(date);
        const day = today.getDate();
        return day;
    }

    const [minDate, setMinDate] = useState(new Date());
    const [maxDate, setMaxDate] = useState(new Date());
    const [ranks, setRanks] = useState([]);
    const [allDate, setAllDate] = useState([]);
    const [allMonth, setAllMonth] = useState([]);
    const [state, setState] = useState({ right: false });
    const sevenDaysEarlier = new Date();
    const [stateD, setStateD] = useState([{
        startDate: new Date(sevenDaysEarlier.setDate(sevenDaysEarlier.getDate() - 7)),
        endDate: new Date(),
        key: 'selection',
        color: '#3730a3'
    }]);

    const [startQ, setStartQ] = useState(convertDate(stateD[0].startDate));
    const [endQ, setEndQ] = useState(convertDate(stateD[0].endDate));

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const paramStartDate = searchParams.get("start") || convertDate(stateD[0].startDate);
        const paramEndDate = searchParams.get("end") || convertDate(stateD[0].endDate);
        setStartQ(convertDate(paramStartDate));
        setEndQ(convertDate(paramEndDate));
        setStateD([{
            startDate: new Date(paramStartDate),
            endDate: new Date(paramEndDate),
            key: 'selection',
            color: '#3730a3'
        }]);
    }, []);

    useEffect(() => {
        axios.get(`https://seo-report-api.8content.com/${slug}`, {
            params: {
                start: startQ,
                end: endQ
            }
        }).then(function (response) {
            setMinDate(response.data.data.minMax.min);
            setMaxDate(response.data.data.minMax.max);
            setAllDate(response.data.data.date);
            setAllMonth(response.data.data.dm);

            const groupedData = response.data.data.all.reduce((acc, curr) => {
                const { date, keyword, rank } = curr;
                if (!acc[keyword]) {
                    acc[keyword] = { name: keyword, data: [] };
                }
                acc[keyword].data.push({ date, rank });
                return acc;
            }, {});

            setRanks(Object.values(groupedData));

        })
    }, [slug, startQ, endQ]);

    const updateSearchParams = (start, end) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("start", start);
        searchParams.set("end", end);
        const newUrl = window.location.pathname + "?" + searchParams.toString();
        window.history.pushState({ path: newUrl }, "", newUrl);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const colors = ['#36a2eb', '#bedb39', '#ff6384', '#ff9f40', '#9966ff', '#d50000', '#673ab7', '#33691e'];

    return (
        <div id="main" className="bg-slate-50">
            <div className="flex justify-between items-center p-4 bg-white" style={{ boxShadow: '0 0 120px 0 rgb(0 0 0 / 15%)' }}>
                <img src={logo} className="w-24" alt="logo" />
                <div className="flex gap-4">
                    <button onClick={toggleDrawer('right', true)}>
                        <MenuOutlinedIcon />
                    </button>
                </div>
            </div>
            <div className="container mx-auto p-8">
                <div className="" style={{ minHeight: 'calc(100vh - 150px)' }}>
                    <div className="">
                        <div className="md:flex justify-between items-center mb-8 md:mb-2">
                            <div>
                                <h1 className="md:text-2xl text-xl font-bold mb-2">SEO Report</h1>
                                <p className="mb-8">{slug} {` ระหว่างวันที่ ${convertDate(startQ)} ถึง ${convertDate(endQ)}`}</p>
                            </div>
                            <Button
                                variant="contained"
                                className="flex items-center gap-4 w-full md:w-auto"
                                onClick={handleClickOpen}>
                                <CalendarMonthOutlinedIcon /> เลือกช่วงวันที่
                            </Button>
                        </div>

                        {ranks.length > 0 ?
                            <div className="max-w-full overflow-auto mb-4 bg-white">
                                <table className="table-auto w-full border-collapse border border-slate-150">
                                    <thead>
                                        <tr>
                                            <th className="border border-slate-150 p-2 font-bold text-right" colSpan={2}>Month</th>
                                            {allMonth && Object.entries(allMonth).map(([key, item]) => (
                                                <th
                                                    colSpan={allMonth[key][Object.keys(item)].length}
                                                    className="border border-slate-150 p-2 font-bold"
                                                    key={key}
                                                >
                                                    {Object.keys(item)}
                                                </th>
                                            ))}
                                        </tr>
                                        <tr>
                                            <th className="border border-slate-150 p-2 font-bold text-right" colSpan={2}>Date</th>
                                            {allDate && Object.entries(allDate).map(([k, item]) => (
                                                <th className="border border-slate-150 p-2 font-bold bg-indigo-50" key={k}>{convertDate2(item)}</th>
                                            ))}
                                        </tr>
                                        <tr>
                                            <th className="border border-slate-150 p-2 font-bold">No.</th>
                                            <th className="border border-slate-150 p-2 font-bold text-left">Keyword</th>
                                            <th className="border border-slate-150 p-2 font-bold" colSpan={ranks[0].data.length}>Rank</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ranks && Object.entries(ranks).map(([key, keyword]) =>
                                        (
                                            <tr className="hover:bg-indigo-50" key={key}>
                                                <td className="border border-slate-150 p-2 text-center">{parseInt(key) + 1}</td>
                                                <td className="border border-slate-150 p-2 font-bold whitespace-nowrap">
                                                    <a href={`https://www.google.com/search?q=${keyword.name}`} target="_blank" rel="noreferrer">
                                                        {keyword.name}
                                                    </a>
                                                </td>
                                                {Object.entries(keyword.data).map(([k, item]) =>
                                                (
                                                    <td className="border border-slate-150 p-2 text-center" key={k}>{item.rank > 100 ? '100+' : item.rank}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            :
                            <Box sx={{ height: "max-content" }}>
                                {[...Array(10)].map((card, index) => (
                                    <div key={index} className="grid grid-cols-8 gap-2">
                                        {[...Array(8)].map((ca, ind) => (
                                            <Skeleton key={ind} variant="rectangular" sx={{ my: 1, mx: 1 }} />
                                        ))}
                                    </div>
                                ))}
                            </Box>
                        }

                        <Card>
                            <CardContent>
                                <ResponsiveContainer width="100%" height={600} >
                                    <LineChart
                                        //width={1000} height={600}
                                        data={ranks} margin={{ top: 20, right: 30, bottom: 20, left: 0 }} stroke="#ccc" >
                                        <XAxis
                                            dataKey="date"
                                            tick={{ fontSize: 12 }}
                                            //tickFormatter={formatXAxisDate} 
                                            minTickGap={30}
                                            type="category"
                                            allowDuplicatedCategory={false}
                                        />
                                        <YAxis
                                            domain={[1, 100]}
                                            tickFormatter={(value) => (value > 100 ? "100+" : value)}
                                            tick={{ fontSize: 12 }}
                                            reversed={true}
                                        />
                                        <Tooltip formatter={(value) => (value > 100 ? "100+" : value)} />
                                        <Legend
                                        //onClick={handleLegendClick}
                                        />
                                        {ranks.map((keywordData, index) => (
                                            <Line
                                                data={keywordData.data}
                                                dataKey="rank"
                                                name={keywordData.name}
                                                key={keywordData.name}
                                                stroke={colors[index % colors.length]}
                                                strokeWidth={3}
                                                dot={{ r: 4 }}
                                                activeDot={{ r: 8 }} />
                                        ))}
                                        <CartesianGrid strokeDasharray="4" />
                                    </LineChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>


                    </div>
                </div>
            </div>
            <Drawer
                anchor={'right'}
                open={state['right']}
                onClose={toggleDrawer('right', false)}
            >
                <div className="p-8" style={{ minWidth: '250px' }}>
                    <ul className="text-xl space-y-4">
                        <li><a href="https://8content.com/">หน้าหลัก</a></li>
                        <li><a href="https://8content.com/%e0%b8%a3%e0%b8%b1%e0%b8%9a%e0%b8%97%e0%b8%b3%e0%b9%80%e0%b8%a7%e0%b9%87%e0%b8%9a%e0%b9%84%e0%b8%8b%e0%b8%95%e0%b9%8c-2/">เกี่ยวกับเรา</a></li>
                        <li><a href="https://8content.com/services/">บริการของเรา</a></li>
                        <li><a href="https://8content.com/blog/">บทความ</a></li>
                        <li><a href="https://8content.com/%e0%b8%95%e0%b8%b4%e0%b8%94%e0%b8%95%e0%b9%88%e0%b8%ad%e0%b9%80%e0%b8%a3%e0%b8%b2/">ติดต่อเรา</a></li>
                    </ul>
                </div>
            </Drawer>
            <Dialog
                maxWidth={'lg'}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>เลือกช่วงเวลารายงาน</DialogTitle>
                <DialogContent>
                    <DateRangePicker
                        editableDateInputs={true}
                        onChange={item => setStateD([item.selection])}
                        moveRangeOnFirstSelection={false}
                        months={2}
                        ranges={stateD}
                        minDate={new Date(minDate)}
                        maxDate={new Date(maxDate)}
                        color={'#3730a3'}
                        direction="horizontal"
                        staticRanges={[]}
                        inputRanges={[]}
                        renderStaticRangeLabel={null}
                        showDateDisplay={false}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>ยกเลิก</Button>
                    <Button onClick={() => {
                        setStartQ(convertDate(stateD[0].startDate));
                        setEndQ(convertDate(stateD[0].endDate));
                        updateSearchParams(convertDate(stateD[0].startDate), convertDate(stateD[0].endDate));
                        handleClose();
                        //console.log(stateD);
                    }}>ตกลง</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
