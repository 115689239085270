import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import Home from './home';
import Landing from "./landing";
const theme = createTheme({
  typography: {
    fontFamily: ["Noto Sans Thai"].join(","),
  },
  palette: {
    primary: {
      light: "#6e5ad5",
      main: "#3730a3",
      dark: "#000973",
      contrastText: "#fff",
    },
    secondary: {
      light: "#fff154",
      main: "#febf12",
      dark: "#c68f00",
      contrastText: "#000",
    },
  },
});
function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            index
            //path ="/"
            element={<Home />}
          />
          <Route
            path="/:slug"
            element={<Landing />}
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
